<template>
  <div class="container">
    <div class="editor-container">
      <tiptap-editor v-model="content" @save="onSave" />
    </div>

    <div class="right-col">
      <div v-if="!welcomeMessagePictureURL" class="upload-container">
        <template v-if="!isUploadingImg">
          <font-awesome-icon
            class="placeholder"
            :icon="icons.placeholder"
            size="5x"
          />

          <label class="img-uploader">
            Elegir imagen
            <input type="file" @input="onFileUpload" />
          </label>
        </template>
        <loading-spinner v-else />
      </div>
      <div v-else class="img-container">
        <div class="img-mask">
          <div class="btn-container">
            <icon-button
              :icon="icons.upload"
              label="Subir"
              variant="secondary"
            />
            <icon-button
              icon="trash-alt"
              label="Eliminar imagen"
              variant="secondary"
              @click="onDelete"
            />
          </div>
        </div>
        <img :src="welcomeMessagePictureURL" />
      </div>

      <custom-button variant="primary">Comencemos</custom-button>
    </div>

    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { faImage, faUpload } from "@fortawesome/free-solid-svg-icons";

import TiptapEditor from "../../components/TiptapEditor.vue";
import CustomButton from "../../components/CustomButton.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import IconButton from "../../components/IconButton.vue";
import ConfirmDialogue from "../../components/ConfirmDialogue.vue";

export default {
  name: "WelcomeMessage",

  components: {
    TiptapEditor,
    CustomButton,
    LoadingSpinner,
    IconButton,
    ConfirmDialogue
  },

  data: () => ({
    icons: {
      placeholder: faImage,
      upload: faUpload
    },

    isUploadingImg: false,

    content: ""
  }),

  computed: {
    ...mapState({
      welcomeMessagePictureURL: state => state.survey.welcomeMessagePictureURL,
      welcomeMessage: state => state.survey.welcomeMessage
    })
  },

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions([
      "updateWelcomeMessage",
      "uploadWelcomeMessagePicture",
      "deleteWelcomeMessagePicture"
    ]),

    async onSave() {
      try {
        await this.updateWelcomeMessage(this.content);

        this.setAlert({
          state: "success",
          message: "Cambios guardados"
        });

        this.content = this.welcomeMessage;
      } catch (err) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });

        console.log(err);
      }
    },

    async onFileUpload(e) {
      try {
        this.isUploadingImg = true;

        await this.uploadWelcomeMessagePicture(e.target.files[0]);

        this.setAlert({
          state: "success",
          message: "Se subió la imagen"
        });
      } catch (error) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });

        console.log(error);
      } finally {
        this.isUploadingImg = false;
      }
    },

    async onDelete() {
      try {
        const ok = await this.$refs.confirmDialogue.show({
          title: "Eliminar Imagen",
          message:
            "¿Estás seguro que quieres eliminar esta imagen? Una vez eliminada no podrás recuperarla.",
          isDestructive: false,
          okButton: "Sí, eliminar",
          cancelButton: "No, volver"
        });

        if (!ok) return;

        await this.deleteWelcomeMessagePicture();

        this.setAlert({
          state: "success",
          message: "Se eliminó la imagen"
        });
      } catch (error) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });
      }
    }
  },

  mounted() {
    if (this.welcomeMessage) this.content = this.welcomeMessage;
  }
};
</script>

<style scoped>
.editor-container {
  flex-basis: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.upload-container,
.img-container {
  width: 100%;
}

.upload-container {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
}

.placeholder {
  color: var(--grayColor2);
}

.container {
  height: 100%;
  display: flex;
  gap: var(--lengthMd1);
}

.right-col {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn {
  margin-left: auto;
}

.img-uploader {
  color: var(--mainColor1);
  font-weight: var(--medium);
  text-decoration: underline;
  cursor: pointer;
}

.img-uploader input {
  display: none;
}

.img-container {
  position: relative;
}

.img-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  place-items: center;
  background-color: rgba(000, 000, 000, 0.5);
}

.img-container:hover .img-mask {
  display: grid;
}

.btn-container {
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}
</style>
