<template>
  <div class="container">
    <view-hero>
      <template slot="title">Comunicado Masivo</template>
      <template slot="description">
        Comunica el inicio de la encuesta. Crea el mejor
        comunicado para dar inicio a tu encuesta. Envía los
        accesos y gestiona tu información.
      </template>
    </view-hero>
    <custom-input label="Asunto" v-model="emailSubject" @blur="onSaveSubject" />

    <p>Hola (nombre):</p>
    <custom-textarea v-model="content" @blur="onSave" />
    <p>A continuación te brindamos tus credenciales de acceso:</p>
    <div class="flex justify-between">
      <div class="access-info">
        <p>Usuario</p>
        <p>Contraseña</p>
      </div>
      <div>Tienes hasta: Fecha de termino</div>
    </div>
    <custom-textarea v-model="signatureText" @blur="onSaveSignature" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import CustomInput from '../../components/CustomInput.vue';
import CustomTextarea from "../../components/CustomTextarea.vue";
import ViewHero from "../../components/ViewHero.vue";

export default {
  name: "EmailMessage",

  components: { ViewHero, CustomTextarea, CustomInput },

  data: () => ({
    emailSubject: 'Encuesta Clima Laboral',
    signatureText: "¡EQUIPO DE RECURSOS HUMANOS!",

    content:
      "Te invitamos a participar en la encuesta de Clima Laboral 2021, la cual permitirá conocer y evaluar las opiniones de los colaboradores de la organización. Además, brindará la información necesaria para generar un plan de trabajo que logre una mejora en la empresa. La encuesta es confidencial, no se analizarán respuestas individuales, solo el conjunto de datos en general para identificar las oportunidades de mejora. Por favor, tómate el tiempo que sea necesario para contestarla, que sus respuestas sean honestas y objetivas. Los resultados se guardarán automáticamente una vez que culmines toda la encuesta."
  }),

  computed: {
    ...mapState(["survey"])
  },

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions(['updateEmailSubject']),
    ...mapActions(["updateEmailMessage"]),
    ...mapActions(["updateSignature"]),

    async onSave() {
      try {
        await this.updateEmailMessage(this.content);

        this.setAlert({
          state: "success",
          message: "Se guardó el mensaje masivo"
        });
      } catch (err) {
        this.setAlert({
          state: "erorr",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });

        console.log(err);
      }
    },

    async onSaveSubject() {
      try {
        await this.updateEmailSubject(this.emailSubject);

        this.setAlert({
          state: 'success',
          message: 'Se guardó el asunto del mensaje masivo',
        });
      } catch (err) {
        this.setAlert({
          state: 'erorr',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });

        console.log(err);
      }
    },
    
    async onSaveSignature() {
      try {
        await this.updateSignature(this.signatureText);

        this.setAlert({
          state: "success",
          message: "Se guardó la firma del mensaje masivo"
        });
      } catch (err) {
        this.setAlert({
          state: "erorr",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });

        console.log(err);
      }
    }
  },

  mounted() {
    if (this.survey.emailMessage) this.content = this.survey.emailMessage;
    if (this.survey.subject) this.emailSubject = this.survey.subject;
    if (this.survey.signatureText)
      this.signatureText = this.survey.signatureText;
  }
};
</script>

<style scoped>
.editing {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--lengthMd1);
}

.preview {
  height: 100%;
  padding: var(--lengthMd3);
  white-space: pre-wrap;
  border: 1px solid var(--grayColor3);
  border-radius: var(--lengthSm2);
}

.btn-container {
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
  margin-bottom: var(--lengthMd3);
  justify-content: flex-end;
}

.img {
  max-width: 768px;
  margin: var(--lengthMd3) auto 0;
}

.hero {
  background-image: url("../../assets/modules/email-message.jpg");
}
</style>
