<template>
  <div class="container">
    <div class="inner">
      <tiptap-editor v-model="title" @save="onTitleSave" />
      <div class="divider"></div>
      <tiptap-editor v-model="text" @save="onTextSave" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import TiptapEditor from "@/components/TiptapEditor.vue";

export default {
  name: "GoodbyeMessage",

  components: { TiptapEditor },

  data: () => ({
    isEditing: false,
    text: "",
    title: ""
  }),

  computed: {
    ...mapState({
      gbTitle: state => state.survey.goodbyeMessageTitle,
      gbText: state => state.survey.goodbyeMessageText
    })
  },

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions(["updateGoodbyeMessageText", "updateGoodbyeMessageTitle"]),

    async onTextSave() {
      try {
        await this.updateGoodbyeMessageText(this.text);

        this.setAlert({
          state: "success",
          message: "Cambios guardados"
        });
      } catch (err) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });

        console.log(err);
      }
    },

    async onTitleSave() {
      try {
        await this.updateGoodbyeMessageTitle(this.title);

        this.setAlert({
          state: "success",
          message: "Cambios guardados"
        });
      } catch (err) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });

        console.log(err);
      }
    }
  },

  mounted() {
    this.title = this.gbTitle;
    this.text = this.gbText;
  }
};
</script>

<style scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--lengthMd1);
}

.inner {
  height: 100%;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
}

.preview,
.editor {
  min-height: 50px;
  height: auto;
  flex-grow: 0;
}

.divider {
  width: 40px;
  height: 2px;
  flex-shrink: 0;
  margin: 0 auto;
  background-color: var(--mainColor1);
}

.btn-container {
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
  margin-bottom: var(--lengthMd3);
  justify-content: flex-end;
}
</style>
