<template>
  <div class="releases">
    <h2>Comunicados</h2>
    <tab-nav :tabs="tabs" v-model="currentTab" />

    <div class="content">
      <keep-alive>
        <component :is="currentTab.component" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import WelcomeMessage from "./WelcomeMessage.vue";
import GoodbyeMessage from "./GoodbyeMessage.vue";
import EmailMessage from "./EmailMessage.vue";
import TabNav from "@/components/TabNav.vue";

import { mapState } from "vuex";

export default {
  name: "Releases",

  components: {
    TabNav,
    WelcomeMessage,
    EmailMessage,
    GoodbyeMessage
  },

  data() {
    return {
      currentTab: {},

      tabs: [
        {
          name: "Comunicado Masivo",
          component: "email-message"
        },
        {
          name: "Mensaje de Bienvenida",
          component: "welcome-message"
        },
        {
          name: "Mensaje de Agradecimiento",
          component: "goodbye-message"
        }
      ]
    };
  },

  computed: {
    ...mapState(["survey"])
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.survey.active) next("/");
      else next();
    });
  }
};
</script>

<style scoped>
.releases {
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
}
</style>
