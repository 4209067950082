var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isEditing)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onCancel),expression:"onCancel"}],staticClass:"editor"},[(_vm.editor)?_c('div',{staticClass:"editor__menu"},[_c('button',{staticClass:"editor__btn",class:{ 'editor__btn--active': _vm.editor.isActive('bold') },attrs:{"title":"Negrita"},on:{"click":function($event){_vm.editor
          .chain()
          .toggleBold()
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.bold}})],1),_c('button',{staticClass:"editor__btn",class:{ 'editor__btn--active': _vm.editor.isActive('italic') },attrs:{"title":"Cursiva"},on:{"click":function($event){_vm.editor
          .chain()
          .toggleItalic()
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.italic}})],1),_c('button',{staticClass:"editor__btn",class:{ 'editor__btn--active': _vm.editor.isActive('underline') },attrs:{"title":"Subrayado"},on:{"click":function($event){_vm.editor
          .chain()
          .toggleUnderline()
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.underline}})],1),_c('div',{staticClass:"divider"}),_c('button',{staticClass:"editor__btn",class:{
        'editor__btn--active': _vm.editor.isActive('heading', { level: 1 })
      },attrs:{"title":"Título 1"},on:{"click":function($event){_vm.editor
          .chain()
          .toggleHeading({ level: 1 })
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.header}})],1),_c('button',{staticClass:"editor__btn",class:{
        'editor__btn--active': _vm.editor.isActive('bulletList')
      },attrs:{"title":"Lista"},on:{"click":function($event){_vm.editor
          .chain()
          .toggleBulletList()
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.ul}})],1),_c('button',{staticClass:"editor__btn",class:{
        'editor__btn--active': _vm.editor.isActive('orderedList')
      },attrs:{"title":"Lista Ordenada"},on:{"click":function($event){_vm.editor
          .chain()
          .toggleOrderedList()
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.ol}})],1),_c('div',{staticClass:"divider"}),_c('button',{staticClass:"editor__btn",class:{
        'editor__btn--active': _vm.editor.isActive({ textAlign: 'left' })
      },attrs:{"title":"Izquierda"},on:{"click":function($event){_vm.editor
          .chain()
          .setTextAlign('left')
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.left}})],1),_c('button',{staticClass:"editor__btn",class:{
        'editor__btn--active': _vm.editor.isActive({ textAlign: 'center' })
      },attrs:{"title":"Centrar"},on:{"click":function($event){_vm.editor
          .chain()
          .setTextAlign('center')
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.center}})],1),_c('button',{staticClass:"editor__btn",class:{
        'editor__btn--active': _vm.editor.isActive({ textAlign: 'right' })
      },attrs:{"title":"Derecha"},on:{"click":function($event){_vm.editor
          .chain()
          .setTextAlign('right')
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.right}})],1),_c('button',{staticClass:"editor__btn",class:{
        'editor__btn--active': _vm.editor.isActive({ textAlign: 'justify' })
      },attrs:{"title":"Justificar"},on:{"click":function($event){_vm.editor
          .chain()
          .setTextAlign('justify')
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.justify}})],1),_c('div',{staticClass:"divider"}),_c('input',{attrs:{"type":"color"},domProps:{"value":_vm.editor.getAttributes('textStyle').color},on:{"input":function($event){_vm.editor
          .chain()
          .focus()
          .setColor($event.target.value)
          .run()}}}),_c('div',{staticClass:"divider"}),_c('button',{staticClass:"editor__btn",attrs:{"title":"Deshacer"},on:{"click":function($event){_vm.editor
          .chain()
          .undo()
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.undo}})],1),_c('button',{staticClass:"editor__btn",attrs:{"title":"Rehacer"},on:{"click":function($event){_vm.editor
          .chain()
          .redo()
          .focus()
          .run()}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.redo}})],1),(_vm.textChanged)?_c('button',{staticClass:"editor__btn",attrs:{"title":"Guardar"},on:{"click":_vm.onSave}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.save}})],1):_vm._e()]):_vm._e(),_c('editor-content',{staticClass:"editor__editor",attrs:{"editor":_vm.editor}})],1):_c('div',{staticClass:"preview",domProps:{"innerHTML":_vm._s(_vm.value)},on:{"click":_vm.onEdit}})}
var staticRenderFns = []

export { render, staticRenderFns }